<template>
  <div class="h-fill">
    <template v-if="card.createAt">
      <div class="pd-l-15 pd-r-15 pd-b-5 pd-t-15 justify-content-space-between" v-if="!$parent.appInner">
        <img @click="onHomeClick" class="w-160" src="/h5/static/images/header-logo.png"/>
        <div v-if="!$parent.loginUser" class="c-app-green text-strong" @click="$parent.login()">登录</div>
      </div>
      <div class="pd-15">
        <div class="justify-content-space-between ">
          <div class="fs-22 flex-1 lh-28">
      <span v-if="card.mark" class="pd-l-6 pd-r-6 display-inline-block lh-22 h-22 bdr-2 fs-12 mg-r-5"
            style="background: linear-gradient(51deg, #262626 0%, #545454 100%);color: #FFC680;vertical-align: middle;"
      >{{ card.mark }}</span>
            <span class="text-strong" @click="onTitleClick" style="vertical-align: middle;">{{ card.title }}</span>
          </div>
          <div class="w-100 fs-12 c-app-green text-align-right mg-t-4 text-strong">
            {{ card.levelName }} / {{ card.subjectName }}
          </div>
        </div>
        <div class="justify-content-space-between mg-t-10">
          <div class="align-items-center fs-14">
            <div class="c-app-orange">
              <template v-if="$parent.isIos && $parent.appInner">{{ team.price / 100 }}学习币</template>
              <template v-else>￥ {{ team.price / 100 }}</template>
              拼团价
            </div>
            <div class="c-9 fs-12 mg-l-10" style="text-decoration: line-through">
              原价
              <template v-if="$parent.isIos && $parent.appInner">{{ card.price / 100 }}学习币</template>
              <template v-else>￥ {{ card.price / 100 }}</template>
            </div>
            <!--<img class="mg-r-5" width="18" src="/h5/static/images/resource-icon.png"/>{{formatFileSize}}-->
          </div>
          <div class="fs-12 c-9">{{ card.hotValue }}</div>
        </div>
        <div class="display-flex fs-12">
          <div v-for="tag,index in card.tags" :key="tag.tagId"
               class="bg-f3f pd-l-5 pd-r-5 pd-t-2 pd-b-2 bdr-2 mg-r-10 mg-t-10">{{ tag.tagName }}
          </div>
        </div>
      </div>
      <div class="h-15 bg-f3f"></div>
      <div class="pd-15">
        <div class="c-9 fs-12 text-align-center mg-b-5" @click="showTeamDesc = true">
          拼团规则：邀请{{ team.users }}人参团，倒计时截止后人数不足自动退款
          &gt;
        </div>
        <template v-if="team.hasOrder">
          <div v-if="team.status == 9"
               class="justify-content-center align-items-center c-app-green fs-24 mg-t-15">
            拼团成功！
          </div>
          <div v-else-if="team.status == 1 && team.countDown >= 0"
               class="justify-content-center align-items-center c-app-green fs-24 mg-t-15">
            <span class="w-30">{{ countDownH }}</span>:
            <span class="w-30 mg-l-5">{{ countDownM }}</span>:
            <span class="w-30 mg-l-5">{{ countDownS }}</span>
          </div>
          <div v-else-if="team.status != 1 && team.countDown <= 0"
               class="justify-content-center align-items-center fs-12 mg-t-15">
            支付款24小时内原路退还{{ team.countDown < -(3600 * 2) ? '，若超时未到账请联系客服' : '' }}
          </div>
        </template>
        <template v-else>
          <div v-if="team.status != 1" class="justify-content-center align-items-center c-app-green fs-24 mg-t-15">
            该团已结束，开个新团吧！
          </div>
          <div v-else-if="team.countDown >= 0"
               class="justify-content-center align-items-center c-app-green fs-24 mg-t-15">
            <span class="w-30">{{ countDownH }}</span>:
            <span class="w-30 mg-l-5">{{ countDownM }}</span>:
            <span class="w-30 mg-l-5">{{ countDownS }}</span>
          </div>
          <div v-if="team.status == 1 && team.users > team.payCount" class="text-align-center fs-12">
            还差{{ team.users - team.payCount }}人，特享团购价
          </div>
        </template>
        <div style="overflow-x: scroll;margin-left: -15px;margin-right: -15px">
          <div class="mg-t-10 fs-12" :class="team.users<=4?'justify-content-center':'display-flex'">
            <div v-for="position,index in team.users" class="mg-l-5 mg-r-5">
              <template v-if="team.memberList[index]">
                <div class="text-align-center">
                  <img class="w-48 h-48 bdr-circular pd-2 bdc-app-orange border-1"
                       :src="team.memberList[index].avatar"/></div>
                <div class="text-align-center text-ellipsis-1 w-80">{{ team.memberList[index].nickname }}</div>
              </template>
              <template v-else>
                <div class="text-align-center">
                  <img class="w-34 h-34 bdr-circular pd-8 bdc-app-orange border-1" style="border-style: dashed"
                       src="/h5/static/images/empty_seat.jpg">
                </div>
                <div class="text-align-center w-80">拼团</div>
              </template>
            </div>
          </div>
        </div>
        <template v-if="team.status == 9 && team.hasOrder">
          <div v-if="(team.payCount >= team.users || team.status == 9) && team.hasOrder" @click="onDownloadClick"
               class="bg-app-green text-align-center lh-44 c-f bdr-22 mg-t-15">
            下载卡牌
          </div>
        </template>
        <div @click="onNewTeamClick" v-else-if="team.status == 9"
             class="bg-app-green text-align-center lh-44 c-f bdr-22 mg-t-15">
          已满人，发起新的拼团
        </div>

        <div @click="onNewTeamClick" v-else-if="team.status < 0"
             class="bg-app-green text-align-center lh-44 c-f bdr-22 mg-t-15">
          开团失败，发起新的拼团
        </div>

        <div v-else-if="team.hasOrder">
          <div @click="onShareClick" class="bg-app-green text-align-center lh-44 c-f bdr-22 mg-t-15">
            立即邀请好友拼团
          </div>
          <div @click="onRefundClick" class="text-align-center c-app-orange lh-44 bdr-22 mg-t-15">
            取消拼团
          </div>
        </div>

        <div @click="onJoinTeamClick" v-else class="bg-app-orange text-align-center lh-44 c-f bdr-22 mg-t-15">
          <template v-if="$parent.isIos && $parent.appInner">{{ team.price / 100 }}学习币</template>
          <template v-else>￥ {{ team.price / 100 }}</template>
          拼团买
        </div>
      </div>
      <div class="h-15 bg-f3f"></div>
      <template v-if="underwayList.length > 0">
        <div class="text-align-center fs-12 c-9 pd-t-10">你还可以加入这些拼团</div>
        <div>
          <div v-for="item in underwayList" class="justify-content-space-between fs-12 pd-15 "
               style="border-bottom: 1px solid #e8e8e8">
            <div class="align-items-center">
              <img :src="item.avatar" class="w-30 h-30 bdr-circular mg-r-10"/>
              <div>
                {{ item.nickname }}
              </div>
            </div>
            <div class="align-items-center">
              <div class="mg-r-10">
                <div class="text-align-right">还差<span class="c-app-red">{{ item.quota }}</span>人</div>
                <div>剩余 {{ item.countDownTxt }}</div>
              </div>
              <div @click="onUnderwayTeamClick(item)" class="bg-app-orange c-f bdr-5 pd-5">
                去拼团
              </div>
            </div>
          </div>
        </div>
        <div class="h-15 bg-f3f"></div>
      </template>
      <div class="pd-15">
        <template v-if="card.contact.length > 5">
          <div
              v-if="(card.price > 0 && card.purchased) || (card.price === 0 && card.downloadTimes > 0) || card.owner"
              class="pd-10 mg-t-10 fs-14 bg-f3f bdr-5">售后联系方式：{{ card.contact }}
          </div>
          <div v-else class="pd-8 mg-t-10 fs-12 bg-f9f bdr-5 c-a">此作者已提供售后，购买后可见</div>
        </template>
        <div class="mg-t-10 card-content" style="min-height: 100px" v-if="card.content" v-html="card.content"></div>
        <div class="mg-t-10 c-9" style="min-height: 60px" v-if="card.cardId && !card.content">暂无介绍</div>
      </div>
      <div class="h-1 bg-d"></div>
      <div class="c-6 fs-14 mg-15 text-strong">
        <van-icon name="warning-o" color="#0FE7AB"/>
        版权声明：平台资源均由用户制作上传，若存在侵权行为，请发送反馈信件至ankimemory@163.com联系平台进行处理，感谢理解！
      </div>
      <div class="c-app-orange fs-14 mg-15 text-strong">
        <span class="c-app-orange">【购买须知】</span>：此资源为第三方创作者提供，由于虚拟产品的特殊性除商品重大质量缺陷外原则上不可退款，作者提供售后的如需退款请与作者协商沟通。
      </div>
      <template v-if="preview && preview.list">
        <div class="h-20 bg-f3f"></div>
        <div class="mg-t-20 fs-14">
          <div class="mg-l-15 mg-r-15 justify-content-space-between align-items-center">
            <div class="fs-18 text-strong">卡牌预览</div>
            <div>共 {{ preview.noteCount }} 张卡牌</div>
          </div>
          <div class="pd-l-15 pd-r-15 pd-t-15 fs-12 c-9">
            以下是不经过排版的卡牌内容，非实际展示效果，仅用于了解记忆库中的内容
          </div>
          <div v-for="item,index in preview.list"
               :style="!showAllPreview && index > 2?'height: 0 !important;overflow: hidden !important;':''">
            <div class="shadow-2 pd-10  mg-15">
              <div v-for="innerItem in item" class="mg-b-10 text-ellipsis-3">
                <strong class="">{{ innerItem.name }}：</strong>{{ innerItem.value }}
              </div>
            </div>
          </div>
          <div class="text-align-center">
            <a class="display-inline-block c-primary pd-10 c-app-green" @click="showAllPreview = !showAllPreview">{{
                showAllPreview ? '↑收起' : '↓ 展开'
              }}</a>
          </div>
        </div>
      </template>
      <van-dialog v-model="showPayResult" title="支付提示" show-cancel-button confirmButtonText="已支付"
                  cancelButtonText="未支付" @confirm="onPayConfirm" @cancel="onPayCancel">
        <div class="mg-20">
          <div class="text-align-center">是否已支付完成？</div>
          <div v-if="$parent.appInner && copyPayUrl" class="c-app-red fs-14 mg-t-20">正在发起支付，若无反应请复制链接到浏览器打开<span
              class="c-app-blue" @click="onCopyPayUrlClick">点我复制链接</span>
          </div>
        </div>
      </van-dialog>
    </template>
    <van-action-sheet
        v-model="showTeamDesc" cancel-text="关闭" close-on-click-action>
      <div class="fs-16 bg-f pd-15">
        <div class="text-strong">什么是多人拼团？</div>
        <div>多人拼团（以下简称：拼团）是指由多人一起拼单购买的团购活动，通过拼团买家可以享受比直接购买更低价的折扣。</div>
        <div class="text-strong mg-t-10">怎样算拼团成功？</div>
        <div>在拼团有效期内找到满足人数的好友参加拼团，即可算拼团成功。</div>
        <div class="text-strong mg-t-10">拼团失败，怎样退款？</div>
        <div>若拼团有效期内，没有凑齐人数，即算作拼团失败。失败后，按原支付方式自动退款。</div>
      </div>
    </van-action-sheet>
    <van-action-sheet
        v-model="showPayOptions" cancel-text="取消" close-on-click-action>
      <div class="bg-f pd-20 fs-18">
        <div class="c-a fs-14 text-align-center mg-b-30">请选择支付方式</div>
        <div @click="onPaySelectClick('alipay_h5')" class="justify-content-center align-items-center pd-10">
          <img src="/h5/static/images/alipay.png" class="w-20">
          <div class="w-120 text-align-center">支付宝</div>
        </div>
        <div class="h-1 bg-f3f mg-t-15 mg-b-10"></div>
        <div v-if="!morePayWay" @click="morePayWay = true"
             class="justify-content-center align-items-center pd-10 mg-t-10">
          <div class="w-200 text-align-center c-6 fs-12">点此使用其他支付方式&gt;&gt;</div>
        </div>
        <template v-else>
          <div @click="onPaySelectClick('wechat')" class="justify-content-center align-items-center pd-10 mg-t-10">
            <img src="/h5/static/images/wechatpay.png" class="w-20">
            <div class="w-120 text-align-center">微信支付</div>
          </div>
        </template>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

import cardItem from '../components/cardItem'

import request from '../libs/api.request'
import util from '../libs/util'
import storage from "../libs/storage";

export default {
  name: "Card",
  components: {
    cardItem
  },
  data() {
    return {
      teamId: this.$route.params.teamId,
      morePayWay: false,
      downloading: false,
      card: {},
      team: null,
      userAvatar: '',
      userNickname: '',
      userOther: [],
      recommend: [],
      coin: 0,
      appCoin: 0,
      selectPackage: 0,
      rechargePackage: [],
      showPublishNotice: false,
      hasImport: false,
      showPayResult: false,
      copyPayUrl: '',
      showPayOptions: false,
      showTeamDesc: false,
      preview: {},
      showAllPreview: false,
      orderType: '',//订单类型  卡牌或者积分
      countDownH: '00',
      countDownM: '00',
      countDownS: '00',
      countDowner: null,
      shareData: null,
      underwayList: []//其他进行中的团
    }
  },
  computed: {
    formatFileSize() {
      if (this.card.fileSize > 1024 * 1024) {
        return (this.card.fileSize / (1024 * 1024)).toFixed(1) + "MB"
      } else if (this.card.fileSize > 1024) {
        return (this.card.fileSize / (1024)).toFixed(1) + "KB"
      } else {
        return this.card.fileSize + "B"
      }
    },
    purchasedCard() {//已购
      return this.card.owner || this.card.price == 0 || this.card.purchased;
    }
  },
  methods: {
    onUnderwayTeamClick(team) {
      console.log(team);
      location.href = '/h5/team/' + team.teamId;
    },
    onRefundClick() {
      let message = '是否退出此次拼团并退款？\n退款金额原路返回原支付渠道\n(可能有约1~10分钟延时)';
      if (this.$parent.appInner && this.$parent.isIos) {
        message = '是否退出此次拼团并退款？\n退款金额将返回学习币账户';
      }
      this.$dialog.confirm({message: message}).then(() => {
        request.request({
          url: '/order/orderRefund',
          data: {
            order_id: this.team.currentBuyerOrderId,
          },
          method: 'post'
        }).then((data) => {
          this.$dialog.alert({message: '操作成功'}).then(() => {
            location.reload();
          });
        }).catch((err) => {
          this.$toast(err.message);
          if (err.code === 401 || err.code === 403) {
            this.$parent.login();
          }
        });
      })
    },
    unSupportPlatform() {
      if (
          this.$parent.appInner &&
          this.card.platform.length > 0) {
        let p = this.$parent.isIos ? 2 : 1;
        return this.card.platform.indexOf(p) === -1;
      }

      return false;
    },
    onPaySelectClick(payType) {
      if (this.downloading) {
        return;
      }

      if (payType.indexOf("wechat") > -1) {
        if (util.inWechatPC()) {
          this.$dialog.alert({message: "请使用手机版微信打开链接支付"});
          return;
        }
        payType = util.inWechat() ? 'wechat_gzh' : 'wechat_h5'
      } else if (payType.indexOf("alipay") > -1) {
        if (util.inWechat()) {
          this.$parent.showCommonMask("微信内不支持支付宝", "请打开外部浏览器进行支付");
          return;
        }
      } else {
        alert('错误的支付方式');
      }

      this.downloading = true;
      request.request({
        url: '/card/order',
        data: {
          isMobile: /windows phone|iphone|android/ig.test(window.navigator.userAgent) ? 1 : 0,
          cardId: this.card.cardId,
          returnUrl: location.href,
          appInner: this.$parent.appInner ? 1 : 0,
          payType: payType,
          orderType: this.orderType,
          wxOpenId: this.$parent.wxOpenId,
          teamId: this.teamId
        },
        method: 'post'
      }).then((data) => {
        this.downloading = false;
        if (util.inWechat()) {
          WeixinJSBridge.invoke('getBrandWCPayRequest', data.payData, (res) => {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.showPayOptions = false;
              this.$toast.loading({message: '正在查询支付结果', duration: 60000, overlay: true});
              setTimeout(() => {
                location.reload();
              }, 3000);
            }
          });
        } else {
          this.copyPayUrl = data.copyPayUrl;
          if (this.$parent.appInner && this.$parent.isAndroid && util.compareVersionIfMax('3.00.12', this.$parent.appVersion) && payType === 'wechat_h5') {
            this.$dialog.confirm({
              message: '请复制微信支付链接，然后打开手机浏览器粘贴前往支付',
              confirmButtonText: '复制链接'
            }).then(() => {
              this.$parent.execCopy(this.copyPayUrl, false);
              setTimeout(() => {
                this.$dialog.confirm({message: '链接已复制，请支付成功后点击【确认】刷新页面'}).then(() => {
                  location.reload();
                })
              }, 300)
            })
          } else {
            setTimeout(() => {
              this.showPayResult = true;
              this.showPayOptions = false;
            }, 800);
            location.assign(data.payUrl);
          }
        }
      }).catch((err) => {
        setTimeout(() => {
          this.downloading = false;
        }, 600);
        this.$toast(err.message);
        if (err.code === 401 || err.code === 403) {
          this.$parent.login();
        }
      })
    },
    onPayConfirm() {
      let href = location.href;
      this.$toast.loading({message: '', duration: 8000, overlay: true});
      setTimeout(() => {
        location.replace(href.replace(new RegExp("h5pay_redirect=", "gm"), 'h5pay_redirected='));
      }, 1500);
    },
    onPayCancel() {
      let href = location.href;
      location.replace(href.replace(new RegExp("h5pay_redirect=", "gm"), 'h5pay_redirected='));
    },
    onBackClick() {
      this.$router.push({path: '/'});
    },
    onStartUpAppClick() {
      if (!this.$parent.isIos) {
        setTimeout(() => {
          this.$router.push({path: '/download-app'})
        }, 1000);
        location.href = 'scheme://ankichina/home';
      } else {
        location.href = 'https://mp.weixin.qq.com/s?__biz=MzU2OTg0NTAxMw==&mid=2247483671&idx=1&sn=f041df5e0f187f9c3de0d10ecac05d89&chksm=fcf9356dcb8ebc7bde77daa3c70830400b07b8859f25b62ae957e16df718d537592bf7f60c10#rd';
      }
    },
    onJoinTeamClick() {
      if (!this.$parent.loginUser) {
        this.$parent.login();
        return;
      }

      if (this.$parent.appInner && this.$parent.isIos) {
        try {
          akBridge.callHandler("akOrder", 'team:' + this.teamId + '--ios')
        } catch (e) {
          this.$toast('调起下单失败')
        }
      } else {
        this.orderType = 'team';
        this.showPayOptions = true;
      }
    },
    onDownloadClick() {
      sessionStorage.setItem('doAction', 'download');
      this.$router.push({'path': '/card/' + this.team.cardId})
    },
    onNewTeamClick() {
      sessionStorage.setItem('doAction', 'newTeam');
      this.$router.push({'path': '/card/' + this.team.cardId})
    },
    getData() {
      let requestStatus = 0;
      setTimeout(() => {
        if (requestStatus === 0) {
          this.$toast.loading({message: '加载中', duration: 10000});
        }
      }, 1000);
      request.request({
        url: 'card/teamDetail?teamId=' + this.$route.params.teamId,
      }).then(data => {
        requestStatus = 1;
        this.$toast.clear();
        this.userId = data.userId;
        this.userAvatar = data.userAvatar;
        this.userNickname = data.userNickname;
        this.card = data.card;
        this.team = data.team;
        this.shareData = data.shareData;
        this.preview = data.preview;
        this.underwayList = data.underwayList;
        this.startCountDown();
        let shareData = JSON.parse(this.shareData);
        if (util.inWechat()) {
          this.$parent.doWxShare(shareData.title, shareData.desc, shareData.link, shareData.thumb);
        }
        document.title = data.card.title;
        document.querySelector('meta[name="keywords"]').setAttribute('content', shareData.title);
        document.querySelector('meta[name="description"]').setAttribute('content', shareData.desc);
      }).catch(err => {
        requestStatus = 1;
        this.$toast.clear();
        this.$toast(err.message)
      })
    },
    startCountDown() {
      this.computerCountDown();
      if (!this.countDowner) {
        this.countDowner = setInterval(() => {
          this.computerCountDown();
          this.computerUnderway();
        }, 1000);
      }
    },
    computerCountDown() {
      if (this.team.countDown > 0) {
        this.team.countDown--;
        let H = parseInt(this.team.countDown / 3600);
        this.countDownH = H < 10 ? '0' + H : H;
        let M = parseInt((this.team.countDown % 3600) / 60);
        this.countDownM = M < 10 ? '0' + M : M;
        let S = parseInt(this.team.countDown % 60);
        this.countDownS = S < 10 ? '0' + S : S;
      }
    },
    computerUnderway() {
      if (this.underwayList && this.underwayList.length > 0) {
        for (let i = 0; i < this.underwayList.length; i++) {
          this.underwayList[i].countDown--;
          if (this.underwayList[i].countDown <= 0) {
            this.underwayList[i].countDownTxt = '00:00:00';
          } else {
            let H = parseInt(this.underwayList[i].countDown / 3600);
            let countDownH = H < 10 ? '0' + H : H;
            let M = parseInt((this.underwayList[i].countDown % 3600) / 60);
            let countDownM = M < 10 ? '0' + M : M;
            let S = parseInt(this.underwayList[i].countDown % 60);
            let countDownS = S < 10 ? '0' + S : S;
            this.underwayList[i].countDownTxt = countDownH + ':' + countDownM + ':' + countDownS;
          }
        }
      }
    },
    onShareClick() {
      if (util.inWechat()) {
        this.$parent.showWechatShareMask();
      } else {
        this.$parent.execCopy('https://' + location.host + '/h5/team/' + this.teamId, '链接已复制');
      }
    },
    onCopyPayUrlClick() {
      this.$parent.execCopy(this.copyPayUrl, '链接已复制请前往手机浏览器粘贴支付');
    },
    onHomeClick() {
      this.$router.push({path: '/'})
    },
    onTitleClick() {
      this.$router.push({path: '/card/' + this.team.cardId})
    },
  },
  destroyed() {
    if (this.countDowner) {
      clearInterval(this.countDowner);
    }
  },
  mounted() {
    this.openFromAppInner = this.$route.query['fromAppInner'] === 'yes';
    localStorage.removeItem("jumpTeamOrder");
    this.getData();

    if (this.$route.query['h5pay_redirect'] === 'yes') {
      setTimeout(() => {
        this.showPayResult = true;
      }, 100)
    }

    if (this.$parent.authToken) {
      request.request({url: 'user/getCoin',}).then((data) => {
        this.coin = data.coin;
        this.rechargePackage = data.rechargePackage;
      })
      if (this.$parent.appInner && this.$parent.isIos) {
        request.request({url: 'user/getAppCoin',}).then((data) => {
          this.appCoin = data.coin;
        })
      }
    }

    let shareInfo = this.$route.query['share_info'] || sessionStorage.getItem('share_info');
    if (shareInfo) {
      request.request({
        url: '/card/checkDownload',
        params: {
          cardId: this.cardId,
          shareInfo: shareInfo
        }
      }).then((data) => {
        this.canDownload = data.ret;
      })
    }
  }
}


</script>

<style scoped>

</style>
